import { forwardRef, JSX } from 'react';
import { cn } from '@zep/utils';

import { Button, ButtonProps } from './button';

interface IconButtonProps extends Omit<ButtonProps, 'leftSlot' | 'rightSlot'> {
  children: JSX.Element;
}

/**
 * 아이콘 사이즈는 개발자가 직접 설정해 주어야 합니다.
 * @example
 * <IconButton variant="contained" intent="strong" size="sm" {...otherProps}>
 *   <RectangleDashIcon size="md" />
 * </IconButton>
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        className={cn('aspect-square p-0', className)}
        {...props}
        ref={ref}
      />
    );
  },
);

IconButton.displayName = 'IconButton';
