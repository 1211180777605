import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@zep/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap disabled:pointer-events-none',
  {
    variants: {
      variant: {
        contained: 'border border-[rgba(28,27,31,0.04)]',
        transparent: 'border-none bg-transparent',
        outlined: 'border bg-transparent',
      },

      intent: {
        gradient: 'bg-origin-border text-white disabled:text-white/[.54]',
        strong: 'text-white disabled:text-white/[.54]',
        alternative: 'text-violet-600 disabled:text-violet-600/[.54]',
        neutral: 'text-gray-700 disabled:text-gray-700/[.54]',
        destructive: 'text-red-500 disabled:text-red-500/[.54]',
      },

      size: {
        '3xs': 'h-[18px] min-w-[18px] gap-4xs px-[6px] text-caption-md-medium',
        '2xs':
          'h-[26px] min-w-[26px] gap-4xs px-[8px] text-caption-md-semibold',
        xs: 'h-[30px] min-w-[30px] gap-3xs px-[8px] text-caption-lg-semibold',
        sm: 'h-[36px] min-w-[36px] gap-3xs px-[12px] text-body-sm-semibold',
        md: 'h-[40px] min-w-[40px] gap-3xs px-[12px] text-body-md-semibold',
        lg: 'h-[48px] min-w-[48px] gap-3xs px-[16px] text-body-lg-semibold',
      },

      rounded: {
        default: '',
        full: 'rounded-full',
      },
    },

    compoundVariants: [
      // contained button based on intent
      {
        variant: 'contained',
        intent: 'gradient',
        className:
          'bg-gradient-to-r from-violet-400 to-blue-400 hover:from-violet-500 hover:to-blue-500 disabled:from-violet-200 disabled:to-blue-200 disabled:hover:from-violet-200 disabled:hover:to-blue-200',
      },
      {
        variant: 'contained',
        intent: 'strong',
        className:
          'bg-violet-500 hover:bg-violet-600 disabled:bg-violet-200 disabled:hover:bg-violet-200',
      },
      {
        variant: 'contained',
        intent: 'alternative',
        className:
          'bg-violet-50 hover:bg-violet-100 disabled:hover:bg-violet-50',
      },
      {
        variant: 'contained',
        intent: 'neutral',
        className: 'bg-gray-50 hover:bg-gray-100 disabled:hover:bg-gray-50',
      },
      {
        variant: 'contained',
        intent: 'destructive',
        className: 'bg-red-50 hover:bg-red-100 disabled:hover:bg-red-50',
      },

      // transparent button based on intent
      {
        variant: 'transparent',
        intent: 'alternative',
        className: 'hover:bg-opacity-violet-1 disabled:hover:bg-transparent',
      },
      {
        variant: 'transparent',
        intent: 'neutral',
        className: 'hover:bg-opacity-black-1 disabled:hover:bg-transparent ',
      },
      {
        variant: 'transparent',
        intent: 'destructive',
        className: 'hover:bg-[#FF48480A] disabled:hover:bg-transparent',
      },

      // outlined button based on intent
      {
        variant: 'outlined',
        intent: 'alternative',
        className:
          'border-violet-300 hover:bg-opacity-violet-1 disabled:hover:bg-transparent',
      },
      {
        variant: 'outlined',
        intent: 'neutral',
        className:
          'border-gray-200 hover:bg-opacity-black-1 disabled:hover:bg-transparent',
      },
      {
        variant: 'outlined',
        intent: 'destructive',
        className:
          'border-red-300 hover:bg-[#FF48480A] disabled:hover:bg-transparent',
      },

      // default border radius based on size
      {
        size: '3xs',
        rounded: 'default',
        className: 'rounded-[4px]',
      },
      {
        size: ['2xs', 'xs'],
        rounded: 'default',
        className: 'rounded-[6px]',
      },
      {
        size: ['sm', 'md', 'lg'],
        rounded: 'default',
        className: 'rounded-[8px]',
      },
    ],

    defaultVariants: {
      variant: 'contained',
      intent: 'strong',
      size: 'lg',
      rounded: 'default',
    },
  },
);

type DefaultButtonProps = {
  asChild?: false;
  leftSlot?: React.JSX.Element;
  rightSlot?: React.JSX.Element;
};

type AsChildButtonProps = {
  asChild: true;
  leftSlot?: never;
  rightSlot?: never;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> &
  (DefaultButtonProps | AsChildButtonProps);

/**
 * @param leftSlot
 * @param rightSlot
 * 아이콘 사이즈는 개발자가 직접 설정해 주어야 합니다.
 * @example
 * <Button variant="contained" intent="strong" size="sm" leftSlot={<ChevronLeftIcon size="xs" />} rightSlot={<ChevronRightIcon size="xs" />} {...otherProps}>
 *   HELLO
 * </Button>
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'contained',
      intent = 'strong',
      size = 'lg',
      rounded = 'default',
      asChild = false,
      leftSlot,
      rightSlot,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            intent,
            size,
            rounded,
            className,
          }),
        )}
        ref={ref}
        {...props}>
        {asChild ? (
          children
        ) : (
          <>
            {leftSlot}
            {children}
            {rightSlot}
          </>
        )}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
