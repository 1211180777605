import type { SVGProps } from 'react';
import * as React from 'react';
export const Value2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 60 60"
    {...props}>
    <path
      fill="#FFD600"
      d="M0 30C0 13.432 13.432 0 30 0c16.569 0 30 13.432 30 30 0 16.569-13.431 30-30 30C13.432 60 0 46.569 0 30"
    />
    <path
      fill="#fff"
      d="M22.694 39.382v1.146a3.056 3.056 0 0 0 3.056 3.055h15.278a3.056 3.056 0 0 0 3.055-3.055V25.25a3.056 3.056 0 0 0-3.055-3.056h-.764v14.514c0 1.688-1.368 2.674-3.056 2.674z"
    />
    <path
      fill="#fff"
      d="M16.583 19.139a3.056 3.056 0 0 1 3.056-3.056h15.278a3.056 3.056 0 0 1 3.055 3.056v15.277a3.056 3.056 0 0 1-3.055 3.056H19.639a3.056 3.056 0 0 1-3.056-3.056z"
    />
  </svg>
);
