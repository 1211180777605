import ZepIcon from '../../../icons/ZepIcon';

import { ReviewAvatar1 } from './ReviewAvatar1';
import { ReviewAvatar2 } from './ReviewAvatar2';
import { ReviewAvatar3 } from './ReviewAvatar3';
import { ReviewAvatar4 } from './ReviewAvatar4';
import { ReviewAvatar5 } from './ReviewAvatar5';
import { ReviewAvatar6 } from './ReviewAvatar6';
import { ReviewAvatar7 } from './ReviewAvatar7';
import { ReviewAvatar8 } from './ReviewAvatar8';
import { ReviewAvatar9 } from './ReviewAvatar9';
import { ReviewAvatar10 } from './ReviewAvatar10';
import { ReviewAvatar11 } from './ReviewAvatar11';
import { ReviewAvatar12 } from './ReviewAvatar12';
import { Value1 } from './Value1';
import { Value2 } from './Value2';
import { Value3 } from './Value3';
export const ReviewAvatar1Icon = ZepIcon(ReviewAvatar1);
export const ReviewAvatar10Icon = ZepIcon(ReviewAvatar10);
export const ReviewAvatar11Icon = ZepIcon(ReviewAvatar11);
export const ReviewAvatar12Icon = ZepIcon(ReviewAvatar12);
export const ReviewAvatar2Icon = ZepIcon(ReviewAvatar2);
export const ReviewAvatar3Icon = ZepIcon(ReviewAvatar3);
export const ReviewAvatar4Icon = ZepIcon(ReviewAvatar4);
export const ReviewAvatar5Icon = ZepIcon(ReviewAvatar5);
export const ReviewAvatar6Icon = ZepIcon(ReviewAvatar6);
export const ReviewAvatar7Icon = ZepIcon(ReviewAvatar7);
export const ReviewAvatar8Icon = ZepIcon(ReviewAvatar8);
export const ReviewAvatar9Icon = ZepIcon(ReviewAvatar9);
export const Value1Icon = ZepIcon(Value1);
export const Value2Icon = ZepIcon(Value2);
export const Value3Icon = ZepIcon(Value3);
