import { BoltFillIcon } from '@zep/icons';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';

// TODO: 유틸화
export const ProChip = () => (
  <span className="flex w-max items-center gap-4xs rounded-xl border border-opacity bg-gradient-to-r from-[#8478FF] to-[#3DA9FF] bg-origin-border px-sm py-xs text-body-md-semibold text-white">
    <BoltFillIcon size="2xs" fill={ICON_COLOR.inverse} />
    Pro
  </span>
);
