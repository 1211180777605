import { useTranslation } from 'next-i18next';

import {
  ReviewAvatar1Icon,
  ReviewAvatar2Icon,
  ReviewAvatar3Icon,
  ReviewAvatar4Icon,
  ReviewAvatar5Icon,
  ReviewAvatar6Icon,
  ReviewAvatar7Icon,
  ReviewAvatar8Icon,
  ReviewAvatar9Icon,
  ReviewAvatar10Icon,
  ReviewAvatar11Icon,
  ReviewAvatar12Icon,
} from '../icons';

export const Review = () => {
  const { t } = useTranslation();

  const REVIEWS = [
    {
      name: t('landing.review1.name'),
      from: t('landing.review1.from'),
      avatar: <ReviewAvatar1Icon width={36} height={36} />,
      content: t('landing.review1.content'),
    },
    {
      name: t('landing.review2.name'),
      from: t('landing.review2.from'),
      avatar: <ReviewAvatar2Icon width={36} height={36} />,
      content: t('landing.review2.content'),
    },
    {
      name: t('landing.review3.name'),
      from: t('landing.review3.from'),
      avatar: <ReviewAvatar3Icon width={36} height={36} />,
      content: t('landing.review3.content'),
    },
    {
      name: t('landing.review4.name'),
      from: t('landing.review4.from'),
      avatar: <ReviewAvatar4Icon width={36} height={36} />,
      content: t('landing.review4.content'),
    },
    {
      name: t('landing.review5.name'),
      from: t('landing.review5.from'),
      avatar: <ReviewAvatar5Icon width={36} height={36} />,
      content: t('landing.review5.content'),
    },
    {
      name: t('landing.review6.name'),
      from: t('landing.review6.from'),
      avatar: <ReviewAvatar6Icon width={36} height={36} />,
      content: t('landing.review6.content'),
    },
    {
      name: t('landing.review7.name'),
      from: t('landing.review7.from'),
      avatar: <ReviewAvatar7Icon width={36} height={36} />,
      content: t('landing.review7.content'),
    },
    {
      name: t('landing.review8.name'),
      from: t('landing.review8.from'),
      avatar: <ReviewAvatar8Icon width={36} height={36} />,
      content: t('landing.review8.content'),
    },
    {
      name: t('landing.review9.name'),
      from: t('landing.review9.from'),
      avatar: <ReviewAvatar9Icon width={36} height={36} />,
      content: t('landing.review9.content'),
    },
    {
      name: t('landing.review10.name'),
      from: t('landing.review10.from'),
      avatar: <ReviewAvatar10Icon width={36} height={36} />,
      content: t('landing.review10.content'),
    },
    {
      name: t('landing.review11.name'),
      from: t('landing.review11.from'),
      avatar: <ReviewAvatar11Icon width={36} height={36} />,
      content: t('landing.review11.content'),
    },
    {
      name: t('landing.review12.name'),
      from: t('landing.review12.from'),
      avatar: <ReviewAvatar12Icon width={36} height={36} />,
      content: t('landing.review12.content'),
    },
  ];

  return (
    <section className="flex flex-col items-center gap-2xl px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:gap-3xl min-[1025px]:px-[40px] min-[1025px]:py-3xl">
      <div className="flex flex-col items-center gap-sm">
        <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal">
          {t('landing.teacher.title')}
        </h2>
        <p className="text-body-lg-semibold text-neutral">
          {t('landing.teacher.description')}
        </p>
      </div>

      <div className="relative h-[774px] w-full max-w-[1280px] overflow-y-hidden">
        <div className="columns-1 gap-[24px] min-[751px]:columns-3 min-[1025px]:columns-4">
          {REVIEWS.map(review => (
            <ReviewCard key={review.name} {...review} />
          ))}
        </div>
        <div className="absolute bottom-0 h-[100px] w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-white" />
      </div>
    </section>
  );
};

export const ReviewCard = ({
  name,
  from,
  avatar,
  content,
}: {
  name: string;
  from: string;
  avatar: React.JSX.Element;
  content: string;
}) => {
  const { t } = useTranslation();

  return (
    <figure className="mx-auto mb-md flex w-full max-w-[500px] break-inside-avoid flex-col gap-md rounded-md border border-alternative px-md py-lg shadow-sm min-[751px]:mb-[24px] min-[751px]:gap-lg min-[751px]:px-lg min-[751px]:py-xl">
      <figcaption className="flex items-center gap-xs">
        {avatar}
        <div className="flex flex-col gap-4xs">
          <span className="text-caption-md-medium text-neutral">{from}</span>
          <span className="text-body-sm-semibold text-neutral">
            {name} {t('signup.selectType.teacherTitle')}
          </span>
        </div>
      </figcaption>

      <blockquote className="text-body-lg-medium text-default">
        {content}
      </blockquote>
    </figure>
  );
};
