import { Trans, useTranslation } from 'next-i18next';

export const GoogleEducation = () => {
  const { t } = useTranslation();

  return (
    <section className="flex flex-col items-center gap-lg bg-neutral px-[16px] py-4xl min-[751px]:px-[24px] min-[1025px]:px-[40px]">
      <img
        className="w-[348px] min-[750px]:w-[560px]"
        src={'/assets/google-zep-logo.svg'}
        alt="Google for Education"
      />

      <p className="text-center text-body-lg-medium text-neutral">
        <Trans
          t={t}
          i18nKey={'landing.googleLogo.description'}
          components={{
            Comp: <StrongText text={t('landing.googleLogo.accent')} />,
          }}
        />
      </p>
    </section>
  );
};

const StrongText = (props: { text: string }) => (
  <strong className="text-body-lg-bold text-default">{props.text}</strong>
);
