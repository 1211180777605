import Footer from '@zep/components/Footer';
import { useBodyAttribute, useViewportDevice } from '@zep/hooks';
import {
  Achievement,
  Ai,
  Cta,
  GoogleEducation,
  Introduction,
  Pricing,
  Review,
  Students,
  Teacher,
} from '@zep/module/landing/components';
import { match } from 'ts-pattern';

export const Landing = () => {
  const { isMobile } = useViewportDevice();
  const isStickBannerShow =
    useBodyAttribute('data-is-stick-banner-show') === 'true';

  return (
    <div
      className={
        // STICK_BANNER_HEIGHT = 80, MOBILE_STICK_BANNER_HEIGHT = 64, HEADER_HEIGHT = 65
        match({ isStickBannerShow, isMobile })
          .with(
            { isStickBannerShow: true, isMobile: false },
            () => 'pt-[145px]',
          )
          .with({ isStickBannerShow: true, isMobile: true }, () => 'pt-[129px]')
          .otherwise(() => 'pt-[65px]')
      }>
      <div className="flex flex-col gap-md min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <Cta />
        <Introduction />
        <Achievement />
        <GoogleEducation />
        <Review />
        <Teacher />
        <Ai />
        <Students />
        <div>
          <Pricing />
          <Footer />
        </div>
      </div>
    </div>
  );
};
