import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { ProChip } from './ProChip';

export const Ai = () => {
  const { t } = useTranslation();

  return (
    <section className="px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:px-[40px] min-[1025px]:py-3xl">
      <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-lg min-[751px]:items-stretch min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal min-[751px]:text-left">
          {t('landing.aiFeatures.title')}
        </h2>

        <Function1 />
      </div>
    </section>
  );
};

const IMAGE_LINKS: Record<string, string> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/I8PJ83wK87durTxdqoCzAbe/RD5Pu3wK87hecf1830de4b7bb87f44ff01400af2f5curTxdskcgVwi/quiz_landing_2_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/QhtA93wK87durTxdqoxWldY/AgfY93wK87he0075788bb89766c75465c4f56fa5123urTxdsltV8uk/quiz_landing_2_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/LnbQt3wK87curTxdqomseuE/bL8Sg3wK87hc9aa2b5d535ef0d44ba8edf1c9208a21urTxdspWW2A6/quiz_landing_2_image_jp_2x.webp',
};

const Function1 = () => {
  const { t } = useTranslation();
  const { locale = 'en' } = useRouter();

  return (
    <article className="border-opacity-black-2 flex w-full max-w-[500px] flex-col overflow-hidden rounded-lg border bg-green-50 min-[751px]:max-w-none min-[751px]:flex-row">
      <div className="flex-1">
        <div className="flex size-full flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pr-0 min-[1025px]:p-3xl min-[1025px]:pr-0">
          <div className="flex flex-col items-center gap-md min-[751px]:items-stretch">
            <ProChip />
            <h3 className="text-center text-heading-lg-extrabold text-mint-900 min-[750px]:text-heading-xl-extrabold min-[751px]:text-left">
              {t('landing.aiFeature1.title')}
            </h3>
          </div>
          <p className="text-center text-body-lg-bold text-opacity-black-9 min-[751px]:text-left min-[751px]:text-heading-sm-bold">
            {t('landing.aiFeature1.content')}
          </p>
        </div>
      </div>

      <div className="flex-1 min-[751px]:h-[310px] min-[1025px]:h-[374px]">
        <img
          src={IMAGE_LINKS[locale] || IMAGE_LINKS.en}
          alt="Function 1"
          className="size-full min-[751px]:object-cover min-[751px]:object-left"
        />
      </div>
    </article>
  );
};
