import type { SVGProps } from 'react';
import * as React from 'react';
export const Value3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 60 60"
    {...props}>
    <rect width={60} height={60} fill="#0D94FF" rx={30} />
    <path
      fill="#fff"
      d="M42.125 27.783c1.3.776 1.3 2.658 0 3.434l-15.85 9.466c-1.333.797-3.025-.164-3.025-1.717V20.034c0-1.553 1.692-2.514 3.026-1.717z"
    />
  </svg>
);
